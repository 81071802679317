import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CSSPropertiesWithVars } from "./ChatBubble";
import axios from "axios";
import { setImages } from "../../../store/messages";


export const UsersOnline: React.FC = () => {

    const { users } = useAppSelector(state => state.usersOnline);
    const { player, update } = useAppSelector(state => state.appUser);
    const { images } = useAppSelector(state => state.messages);
    const baseLeftPercentage = 100 / 1000 * 100;
    const incrementPercentage = 30 / 1000 * 100;
    const playerRef = useRef(player);
    const imagesRef = useRef(images);
    const dispatch = useAppDispatch();

    useEffect(() => {
        playerRef.current = player;
        imagesRef.current = images;
    }, [player, images])

    // listen for update firing so that new images can be collected (update is fired by readPlayerMe and The updateAvatar ws message)
    useEffect(() => {
        users.forEach(user => {
            if (user.playerId !== "54321") {
                loadImage(user.avatarUrl.replace(".glb", ".png"), user.playerId);
            }
        })
    }, [update])

    // load images on Mounting of component
    useEffect(() => {
        users.forEach(user => {
            if (user.playerId !== "54321") {
                loadImage(user.avatarUrl.replace(".glb", ".png"), user.playerId);
            }
        })
    }, [])

    // fetch the image from ready player me with no cache header to force update of png
    const loadImage = async (url: string, playerId: string) => {
        if(url.endsWith(".vrm")){
            url = url.replace(".vrm",".png")
        }
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Cache-Control': 'no-cache'
                }
            });

            const imageUrl = URL.createObjectURL(response.data);

            const imgElements = document.getElementsByClassName(playerId) as HTMLCollectionOf<HTMLImageElement>;

            for (let i = 0; i < imgElements.length; i++) {
                const imgElement = imgElements[i];
                imgElement.src = imageUrl;
            }
            updateImages(playerId, imageUrl)

        } catch (error) {
            console.error('Error loading image:', error);
        }
    };

    // update or store the imageUrl and playerId in redux to use in the chat bubble component
    const updateImages = (playerId: string, objectUrl: string) => {
        let currentImages = [...imagesRef.current];

        const existingUserIndex = currentImages.findIndex(existingUser => existingUser.playerId === playerId);

        if (existingUserIndex === -1) {
            currentImages.push({ playerId, imageObjecturl: objectUrl });
        } else {
            currentImages[existingUserIndex] = { playerId, imageObjecturl: objectUrl };
        }

        dispatch(setImages(currentImages));
    };
    
    return(
        <div className="paw-container-online" style={{width: '100%', minWidth: '325px'}}>
            {users.map((user, index) => (
                index < 4 ? 
                    <div className="image-container-online" style={
                        {
                            position: 'relative', 
                            borderRadius: '50%',  
                            left: `${baseLeftPercentage * index /45}%`,
                            zIndex: users.length + index,
                        } as CSSPropertiesWithVars
                    }>
                        <div className="users-online-inner-image-container">
                        <img 
                            className={`user-online-image ${user.playerId} ${user.avatarUrl === "https://avatars.nl-ams-1.linodeobjects.com/avatar_01.vrm" ? "soledad":""}`}
                            key={index}
                            alt=""
                            src={user.playerId === "54321" ? user.avatarUrl : ""}
                            style={{ 
                                borderRadius: '50%', 
                            }}
                            title={user.displayName}
                        /> 
                        </div>
                    </div>
                    :
                    index > 4 ? null : <div className="image-container-online" style={
                        {
                            '--gradient-color': "rgb(100,234,255)",
                            borderRadius: '15%', 
                            position: 'relative', 
                            padding: '11px',
                            color: 'white',  
                            left:  `${baseLeftPercentage * index / 45}%`,
                            zIndex: users.length + index,
                            height: 18,
                            width: 18,
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            background: 'linear-gradient(36deg, rgba(44,128,0,1) 30%, rgba(40,179,238,1) 68%)'
                        } as CSSPropertiesWithVars
                    }>
                     +{(users.length) - 4}
                       
                    </div>
                ))}
        </div>
    )
}