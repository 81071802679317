import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DebugPanel } from '../Debug/DebugPanel';
import { setWebsocket } from '../../store/debug';
import ChatWindow from '../LiveChat/ChatWindow';
import { setMessageHistory, setUnityMessage } from '../../store/messages';
import { cleanMessage } from './chatWindowComponents/ChatBubble';
import { TypingMessage } from './chatWindowComponents/Enums';
import { IMessage } from 'react-stomp-hooks';
import ThreePIcon from '@mui/icons-material/ThreeP';

type TypingUsers = {
  playerId: string,
  user: string,
  timestamp: string
}

type Props = {
  room: string
}
const LiveChat: React.FC<Props> = ({ room }) => {

  const dispatch = useAppDispatch()
  const [show, setShowChat] = useState(false)
  const [showDebug, setShowDebug] = useState(false)
  const [showPending, setShowPending] = useState("")
  const [isTyping, setIsTyping] = useState("")
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isPositionToggled, setIsPositionToggled] = useState(false);
  const [typingUsers, setTypingUsers] = useState<TypingUsers[]>([])
  const { messageHistory,unityMessage } = useAppSelector((state) => state.messages)
  const { player, id } = useAppSelector((state) => state.appUser)
  const { users } = useAppSelector(state => state.usersOnline);
  var location = window.location;
  var baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");

  // Refs
  const showRef = useRef(show);
  const focusRef = useRef(isFocused)
  const messageRef = useRef(messageHistory)
  const typingUsersRef = useRef(typingUsers)
  const playerIdReF = useRef(player.playerId)
  const usersOnlineRef = useRef(users)

  // Check and update Refs
  useEffect(() => {
    showRef.current = show;
    focusRef.current = isFocused;
    typingUsersRef.current = typingUsers;
    playerIdReF.current = id;
    messageRef.current = messageHistory;
    usersOnlineRef.current = users;
  }, [show, isFocused, typingUsers, player, messageHistory, users, id]);


  // Listen For "Enter" to Show and hide Chat
  useEffect(() => {
    const handleKeyPress = (event: { key: string; }) => {
      if (event.key === 'Enter' && !focusRef.current) {
        handleShowChat()
      }
    };
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  // Chat Subscription Handler
  useEffect(() => {
    window.STOMP.subscribe("/topic/chat", (frame: any) => {
      handleWebsocketMessages(frame, "global")
    });
    window.STOMP.subscribe("/topic/chat/" + room, (frame: any) => {
      handleWebsocketMessages(frame, "room")
    });
    window.STOMP.subscribe("/user/queue/party", (frame: any) => {
      handleUserQueuePartyMessage(frame)
    })
    const intervalId = setInterval(() => {
      const now = Date.now();
      let currentTypingUsers = [...typingUsersRef.current];

      currentTypingUsers = currentTypingUsers.filter(userObj => {
        const userTimestamp = new Date(userObj.timestamp).getTime();
        return (now - userTimestamp) <= 800;
      });

      setTypingUsers(currentTypingUsers);
      setIsTyping(setTyping())
    }, 1000);
  }, [])

  // incoming message listener
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== baseUrl) return;
      const message = event.data;
      if (message === "debug") {
        setShowDebug(true)
      } else if (message === "Connected") {
        dispatch(setWebsocket("Connected"))
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [baseUrl]);


  //listen for window open chat change and show chat accordingly
  useEffect(() => {
    const handleOpenChatChange = () => {
      if (window.openChat) {
        setShowChat(true);
        setShowPending("none");
        newMessage();

      }
    };

    window.addEventListener('openChatChange', handleOpenChatChange);

    return () => {
      window.removeEventListener('openChatChange', handleOpenChatChange);
    };
  }, []);

  useEffect(() => {
    console.log("Message: " + unityMessage)
    let data;
    try {
        data = JSON.parse(unityMessage);
    } catch (e) {
        console.error("Invalid JSON received:", unityMessage);
        return;
    }

    if (data.type === "unityMessage") {
        formatUnityMessages(unityMessage);
    }
}, [unityMessage]);


  //handle websocket messages
  function handleWebsocketMessages(frame: any, from: string) {
    if (frame.body.body === TypingMessage.IS_TYPING) {
      if (frame.body.playerId !== playerIdReF.current) {
        setIsTyping(handleIsTyping(frame.body.from, frame.body.timestamp, frame.body.playerId))
      }
    } else if (frame.body.body === TypingMessage.STOPPED_TYPING) {
      console.log("Removing message")
      let currentTypingUsers = [...typingUsersRef.current];
      currentTypingUsers = currentTypingUsers.filter(userObj => userObj.playerId !== frame.body.playerId);
      setTypingUsers(currentTypingUsers);
      setIsTyping("")
    } else {
      handelMessageTypes(JSON.stringify(frame.body), from)
    }
  }



  // display chat on button click
  function handleShowChat() {
    setShowChat(!showRef.current)
    setShowPending("none")
    setIsPositionToggled(!isPositionToggled)
  }

  //Check the typing array to see if the user typing is present, if so update if not add
  function handleIsTyping(from: string, timestamp: string, playerId: string) {
    let currentTypingUsers = [...typingUsersRef.current];
    const userIndex = currentTypingUsers.findIndex(userObj => userObj.user === from);
    if (userIndex !== -1) {
      currentTypingUsers[userIndex].timestamp = timestamp;
    } else {
      currentTypingUsers.push({ playerId: playerId, user: from, timestamp: timestamp });
    }

    setTypingUsers(currentTypingUsers);
    return setTyping()

  }

  // Display typing status
  function setTyping(): string {
    if (typingUsersRef.current.length === 0) {
      return "";
    } else if (typingUsersRef.current.length === 1) {
      return `${typingUsersRef.current[0].user} is typing...`;
    } else {
      return `Multiple people are typing..."`;
    }
  }

  // get the Avatar images from the users Online when message recieved
  function getAvatar(id: string): string | undefined {
    let url = usersOnlineRef.current.find(user => user.playerId === id)?.avatarUrl.replace(".glb", ".png");
    return url === undefined ? "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png" : url;
  }

  function handelMessageTypes(message: any, type: string) {
    let data = JSON.parse(message)
    switch (type) {
      case "global":
        formatMessages(data)
        break;
      case "room":
        data.forEach((element: any) => {
          if (element.body === TypingMessage.IS_TYPING) {
            if (element.playerId !== playerIdReF.current) {
              setIsTyping(handleIsTyping(element.from, element.timestamp, element.playerId))
            }
          } else if (element.body === TypingMessage.STOPPED_TYPING) {
            console.log("Removing message")
            let currentTypingUsers = [...typingUsersRef.current];
            currentTypingUsers = currentTypingUsers.filter(userObj => userObj.playerId !== element.playerId);
            setTypingUsers(currentTypingUsers);
            setIsTyping("")
          } else {
            formatMessages(element)
          }
        });
        break;
      default:
        console.log("Missing Message Type")
        break;
    }
  }

  // add the incoming messages to the message history state array and attach timestamp, only store up to 500 messages
  function formatMessages(data: any) {
    console.log(data)
    let currentMessages: Array<any> = [...messageRef.current]

    if (currentMessages.length > 200) {

      currentMessages.shift()
      currentMessages.push(
        {
          from: cleanMessage(data.from),
          msg: data.msg ? cleanMessage(data.msg) : cleanMessage(data.body),
          playerId: data.playerId ? data.playerId : "1234",
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          avatar: getAvatar(data.playerId ? data.playerId : "1234")
        })
      dispatch(setMessageHistory(currentMessages))

    } else {
      currentMessages.push(
        {
          from: cleanMessage(data.from),
          msg: data.msg ? cleanMessage(data.msg) : cleanMessage(data.body),
          playerId: data.playerId ? data.playerId : "1234",
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          avatar: getAvatar(data.playerId ? data.playerId : "1234")
        })
      dispatch(setMessageHistory(currentMessages))

    }
    newMessage()
  }

   // Handle Messages From Unity/System/Party
   function formatUnityMessages(message: string) {
    let data = JSON.parse(message)
    let currentMessages: Array<any> = [...messageRef.current];
    let messageUnity = { 
      from: "System", 
      msg: data.msg.textMessage, 
      playerId: "54321", 
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), 
      avatar: getAvatar("54321"),
      partyMessage: data.msg, 
      responded: data.responded };

    const messageExists = currentMessages.some(msg => msg.msg === messageUnity.msg);
    if (!messageExists) {
        if (currentMessages.length > 200) {
            currentMessages.shift();
        }
        currentMessages.push(messageUnity);
        dispatch(setMessageHistory(currentMessages));
    }
}

  // Show alert for unread Messages
  function newMessage() {
    if (!showRef.current) {
      setShowPending("")
    }
  }

  const handleUserQueuePartyMessage = (message:IMessage) => {
    console.log(message)
    // let data = JSON.parse(message.body)   
    let responded = false
    dispatch(setUnityMessage(JSON.stringify({type: "unityMessage", msg: message.body, responded: responded})))  
}

  return (
    <div className="live-chat">
      <div
        className="chat"
        style={{
          width: show ? '60%' : '0%',
          display: show ? "" : "none",
          minWidth: '100px',
          maxWidth: '400px'
        }}
      >
        <ChatWindow setIsFocused={setIsFocused} isTyping={isTyping} room={room} />
      </div>
      <div>
        <div style={{ display: 'inline-block', position: 'fixed', bottom: '2%', right: isPositionToggled ? "450px" : "30px", zIndex: 1}}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: 'green',
              position: 'absolute',
              bottom: '55px',
              right: '-15px',
              display: showPending,
              zIndex: 260000
            }}
          ></div>
          <button
            onClick={handleShowChat}
            className='unityBtn'
            style={{ position: 'relative', zIndex: 1, padding: '33%' }}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
          >
            <ThreePIcon />
          </button>
        </div>
      </div>
      {showDebug && (<DebugPanel setShowDebug={setShowDebug} />)}
    </div>
  )
}

export default LiveChat;