import { Route, Routes} from 'react-router-dom';
import './App.css';
import React, { Fragment, useEffect} from "react";
import ReadyPlayerMeCreator from './components/ReadyPlayerMe/ReadyPlayerMeCreator';
import { InboundLinkRouting } from './components/InboundLinkRouting';
import { LandingPage } from './components/LandingPage/LandingPage';


const App: React.FC = () => {

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      } 
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  return (
      <Fragment>
            
                <Routes> 
                    <Route
                      path="/"
                      element={<Fragment>
                          <LandingPage/>
                        </Fragment>}
                    />
                    <Route
                      path="/mv/:id"
                      element={<Fragment>
                          <InboundLinkRouting/>
                        </Fragment>}
                    />
                    <Route
                      path="/mv"
                      element={<Fragment>
                          <InboundLinkRouting/>
                        </Fragment>}
                    />
                    <Route 
                      path="/mv/editor"
                      element={<ReadyPlayerMeCreator 
                                  style={{ width: "100vw", height: "100vh", border: "none", margin: 0, zIndex:4500000, borderRadius: 'none' }} 
                                  width='100vw' 
                                  height='100vh'
                                  close={null}
                                  isModal={false}
                                  sendMessage={null}
                                  />}
                    />
                </Routes>
        </Fragment>
  );
}

export default App;