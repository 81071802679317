import React from "react";

export const Footer: React.FC = () => {
  return (
    <footer className="footer-container animate__animated animate__slideInUp">
      <div className="footer-content">
        <div className="footer-logo">
          <img
            src="https://www.doglibre.com/assets/images/svg-icons/token.svg"
            alt="logo"
            className="logo-gif"
            height={60}
          />
          <img
            src="https://www.doglibre.com/assets/images/svg-icons/doglibre.png"
            alt="DogLibre"
            className="logo-text"
            height={60}
          />
        </div>

        <div className="footer-copyright">
          © 2024 DogLibre — All Rights Reserved.
        </div>

        <div className="footer-socials">
          <a href="https://www.doglibre.com/en" target="_blank" rel="noopener noreferrer">
            <img src="/images/socials/Website.png" alt="Website" />
          </a>
          <a href="https://www.instagram.com/doglibre/" target="_blank" rel="noopener noreferrer">
            <img src="/images/socials/Instagram.png" alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/company/doglibre/" target="_blank" rel="noopener noreferrer">
            <img src="/images/socials/LinkedIn.png" alt="LinkedIn" />
          </a>
          <a href="https://www.tiktok.com/@doglibre" target="_blank" rel="noopener noreferrer">
            <img src="/images/socials/Tiktok.png" alt="Tiktok" />
          </a>
          <a href="https://www.facebook.com/DogLibre/" target="_blank" rel="noopener noreferrer">
            <img src="/images/socials/Facebook.png" alt="Facebook" />
          </a>
        </div>
      </div>
    </footer>
  );
};
