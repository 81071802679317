import { useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { useParams } from "react-router";

export const globalIsHost = {
    isHost: false
} 


export const InboundLinkRouting: React.FC = () => {

    const {id} = useParams<RouteParams>();
    const [hasCookie, setHasCookie] = useState(false);
    const bypassId = "demo";


    // probably wont need this as is Cookie Based
    // useEffect(() => {
    //     let avatarUrl = Cookies.get("avatarUrl");
    //     let username = Cookies.get("username");

    //     if (avatarUrl === undefined || username === undefined) {
    //         setHasCookie(false);
    //     }
    // }, []);

    if (id === bypassId) {
        return <UnityClient id={id} />;
    }

    return(
        <div className="App">
            
            {
                hasCookie ? <UnityClient id={id}/> : <AvatarPage redirect={setHasCookie}/>
            }
        </div>
    )
}