import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState, Fragment, useRef } from "react";
import LiveChat from './LiveChat/LiveChat';
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setUsers, User } from "../store/usersOnline";
import { UpdateAvatarModal } from "./ReadyPlayerMe/UpdateAvatarModal";
import { setId, setSessionId, setUpdate } from "../store/appUser";
import { setWebsocket } from "../store/debug";
import LoadingPage from "./LoadingPage/LoadingPage";
import { Profile } from "./Profile";

type Props = {
  id: string | undefined
}
export interface RouteParams extends Record<string, string | undefined> {
  id: string;
}
export const GlobalRefs = {
  sendMessage: null as ReactUnityEventParameter | null,

}

type UnityConfig = {
  readonly streamingAssetsUrl?: string;
};

type disconnect = {
  timestamp: string,
  playerId: string
}

export const UnityClient: React.FC<Props> = ({ id }) => {

  const colors = [
    "#00FF00", "#A078D2", "#64EAFF", "#E6E6FA", "#008B8B", "#90EE90", "#9400D3", "#87CEEB", "#006400", "#FFD700",
    "#DAA520", "#B8860B", "#BA55D3", "#9370DB", "#8A2BE2", "#4B0082", "#483D8B", "#6A5ACD", "#7B68EE", "#4682B4",
    "#4169E1", "#0000FF", "#1E90FF", "#00BFFF", "#5F9EA0", "#20B2AA", "#3CB371", "#2E8B57", "#006400", "#9ACD32",
    "#32CD32", "#00FF7F", "#00FA9A", "#66CDAA", "#8FBC8F", "#228B22", "#008000", "#808000", "#6B8E23", "#556B2F",
    "#DEB887", "#D2B48C", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#FFE4B5", "#F0E68C", "#EEE8AA", "#FAFAD2", "#FFFFE0",
    "#FFFF00", "#FFD700", "#FFD700", "#DAA520", "#B8860B", "#FFD700", "#BA55D3", "#9370DB", "#8A2BE2", "#4B0082",
    "#483D8B", "#6A5ACD", "#7B68EE", "#4682B4", "#4169E1", "#0000FF", "#1E90FF", "#00BFFF", "#5F9EA0", "#20B2AA",
    "#3CB371", "#2E8B57", "#006400"
  ];

  const loader = process.env.REACT_APP_UNITY_BUILD_FILE_LOADER
  const data = process.env.REACT_APP_UNITY_BUILD_FILE_DATA
  const framework = process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK
  const code = process.env.REACT_APP_UNITY_BUILD_FILE_WASM
  const streamingAssests =  process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS

  const { unityProvider, isLoaded, loadingProgression, sendMessage, requestPointerLock } = useUnityContext({
        loaderUrl: loader === undefined ?  "" : loader,
        dataUrl:  data === undefined ?  "" : data,
        frameworkUrl:  framework === undefined ?  "" : framework,
        codeUrl:  code === undefined ?  "" : code,
        streamingAssetsUrl: streamingAssests === undefined ?  "" : streamingAssests
  });

  const isFull = process.env.REACT_APP_FULL === "true";
  const dispatch = useAppDispatch()
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { player, update } = useAppSelector(state => state.appUser)
  const [unityComponent, setUnityComponent] = useState<JSX.Element | null>(null);
  const [showUnityClient, setShowUnityClient] = useState(true);
  const [websocketOpen, setWebsocketOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [room, setRoom] = useState(null);
  const { users } = useAppSelector((state) => state.usersOnline)
  const usersRef = useRef(users)
  const updateRef = useRef(update)

  //Update refs
  useEffect(() => {
    usersRef.current = users
    updateRef.current = update
  }, [users, update])

  // check cookies and send to unity
  useEffect(() => {
    if (isLoaded) {
      sendMessage("ReactClientManager", "SetSTOMPConnectHeaders", JSON.stringify({
        authToken: player.authToken,
        moduleId: ""
      }))
    }
  }, [isLoaded])

  useEffect(() => {
    let data = {
      displayName: player.displayName,
      avatarUrl: player.glbUrl,
      colour: player.colour === '#fff' ? colors[Math.floor(Math.random() * colors.length)] : player.colour
    }
    if (isLoaded && isFull) {
      sendMessage("ReactClientManager", "OnAvatarCreationCompleted");
      setTimeout(() => {
        sendMessage("ReactClientManager", "SetLocalAvatar", JSON.stringify(data));
      }, 4000)
;
    }
  }, [setShowUnityClient, isLoaded]);

  useEffect(() => {
    window.sendMessage = sendMessage
  });

  window.OnProfileButtonClicked = (callback?: () => void) => {
      setShowProfile(true)
    if (callback) {
      callback();
    }
  };

  // prepare the unity component    
  useEffect(() => {
    setUnityComponent(<Fragment><Unity className='unity' unityProvider={unityProvider} ref={canvasRef} tabIndex={1} /></Fragment>)
  }, [unityProvider]);

  // set ssend message for unity.js files to use  
  useEffect(() => {
    window.sendMessage = sendMessage
  });

  // subscribe to initialise and setup other subscriptions
  useEffect(() => {
    if (websocketOpen) {
      window.STOMP.subscribe("/user/queue/initialise", (frame: any) => {
        console.log("Setting Room: " + frame.body)
        setRoom(frame.body.room)
        subscribeToUpdateAndDisconnect(frame.body.room)
        updateAvatars(frame.body.avatarDefinitions)
        dispatch(setWebsocket("Connected"))
        dispatch(setSessionId(frame.body.sessionId))
      })
    }
  }, [websocketOpen])

  // Monitor websocket state
  useEffect(() => {
    const handleWebSocketOpened = () => setWebsocketOpen(true);
    const handleWebSocketClosed = () => setWebsocketOpen(false);

    window.STOMP.addEventListener(window.STOMPEvents.on_websocket_opened, handleWebSocketOpened);
    window.STOMP.addEventListener(window.STOMPEvents.on_websocket_closed, handleWebSocketClosed);

    // Cleanup event listeners on component unmount
    return () => {
      window.STOMP.removeEventListener(window.STOMPEvents.on_websocket_opened, handleWebSocketOpened);
      window.STOMP.removeEventListener(window.STOMPEvents.on_websocket_closed, handleWebSocketClosed);
    };
  }, []);

  // Pointer Lock Logic
  useEffect(() => {
	
    var doesUnityClientWantCursorLock = function () {
      return window.UNITY_CLIENT_WANTS_CURSOR_LOCK !== undefined && window.UNITY_CLIENT_WANTS_CURSOR_LOCK && !doesUnityClientWantCursorRelease();
    };
    
    var doesUnityClientWantCursorRelease = function () {
      return window.UNITY_CLIENT_WANTS_CURSOR_RELEASE !== undefined && window.UNITY_CLIENT_WANTS_CURSOR_RELEASE;
    };
    
    var doesUnityClientWantHoverCursor = function () {
      return window.UNITY_CLIENT_WANTS_HOVER_CURSOR !== undefined && window.UNITY_CLIENT_WANTS_HOVER_CURSOR && !doesUnityClientWantCursorLock();
    };
    
    // check we have a unity canvas ready to go
    if(canvasRef.current !== null && isLoaded) {
      
      // hook in to click events to handle pointer locking
      canvasRef.current.addEventListener("click", async function () {
        
        // check the unity client wants cursor lock and request it
        if (doesUnityClientWantCursorLock()) {
          await requestPointerLock();
        }
      });
      
      // method to run each animation frame to let the unity client know what the current cursor lock state is
      var reportPointerLockState = function () {
  
        // tell the unity client the current cursor lock state
        sendMessage("ReactClientManager", "SetCurrentPointerLockState", typeof document.pointerLockElement === "undefined" || document.pointerLockElement === null ? 0 : 1);
  
        // apply the hover cursor or, as long as the cursor is currently shown rather than hidden, default it. if the cursor is actively being released by the unity client, release the lock.
        if (doesUnityClientWantHoverCursor()) {
          if(canvasRef.current != null) {
            canvasRef.current.style.cursor = "pointer";
          }  
        }
        else if (doesUnityClientWantCursorLock()) {
          if(canvasRef.current != null){
            canvasRef.current.style.cursor = "default";
          }
        }
        else if (doesUnityClientWantCursorRelease()) {
          document.exitPointerLock();
        }
  
        // run again the next animation frame
        window.requestAnimationFrame(reportPointerLockState);
      };
      window.requestAnimationFrame(reportPointerLockState);
    };
    
  },[isLoaded])

  // handle Subscriptions
  function subscribeToUpdateAndDisconnect(room: string) {
    window.STOMP.subscribe("/topic/update-avatar/" + room, (frame: any) => {
      updateAvatars(frame.body)
    })
    window.STOMP.subscribe("/topic/disconnect-user/" + room, (frame: any) => {
      disconnectAvatars(frame.body)
    })
  }

  // update avatars online
  function updateAvatars(avatars: User[]) {
    console.log(avatars)

    let currentUsers = [...usersRef.current]
    console.log(currentUsers)
    avatars.forEach((user: User) => {
      const existingUserIndex = currentUsers.findIndex(existingUser => existingUser.playerId === user.playerId);

      if (existingUserIndex === -1) {
        currentUsers.push(user);
      } else {
        currentUsers[existingUserIndex] = user;
      }

      if(user.avatarUrl === player.glbUrl && user.displayName === player.displayName && user.colour === player.colour){ 
        console.log("Setting PlayerId: " + user.playerId)
        dispatch(setId(user.playerId))
      }

    })
    dispatch(setUsers(currentUsers))
    dispatch(setUpdate(!updateRef.current))
  }

  // handle Disconnects
  function disconnectAvatars(avatars: disconnect[]) {
    let currentUsers = [...usersRef.current]
    const disconnectIds = new Set(avatars.map(avatar => avatar.playerId));
    currentUsers = currentUsers.filter(user => !disconnectIds.has(user.playerId));
    dispatch(setUsers(currentUsers))
  }

  return (
    <Fragment>
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      {!isLoaded && showUnityClient && (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 }}>
          <LoadingPage progress={loadingProgression}/>
        </div>
      )}
      {showUnityClient && (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
          {unityComponent}
        </div>
      )}
  
      {isLoaded && websocketOpen && isFull && (
        <>
          <Profile sendMessage={sendMessage}/>
          {room && (
            <div>
              <LiveChat room={room} />
            </div>
          )}
          <UpdateAvatarModal setOpen={setShowProfile} open={showProfile} sendMessage={sendMessage} />
        </>
      )}
    </div>
  </Fragment>
  
  )
}