import React, { useEffect, useState } from "react"
import { WindowSize } from "../AvatarPage/AvatarPage";

type Props = {
  progress: number
}
const LoadingPage: React.FC<Props> = ({progress}) =>{

    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
      });
    const circleScalingFactor = Math.min(windowSize.width / 1800, windowSize.height / 1800);

    useEffect(() => {
      console.log(progress)
    },[progress])
    return(
        <div className="loadingPage">
                  <div className="paw-container-loader">
                  <img height={20} src="/images/paw.png" alt="left paw" className="rotating-paw"/>
                  <img height={80} src="https://www.doglibre.com/assets/images/svg-icons/logo.gif" />
                  <span className="loading-text">Loading</span>
                  <div className="loader">
          <div
            className="loader-progress"
            style={{ width: `${progress*100}%` }} // Set width dynamically based on progress
          ></div>
        </div>
                  <img height={20} src="/images/paw.png" alt="right paw"  className="rotating-paw"/>
                </div>
        </div>
        
    )
}

export default React.memo(LoadingPage);