import React from "react";

export const Header: React.FC = () => {
  return (
    <header className="header animate__animated animate__slideInDown" style={{ padding: "20px", color: "white", textAlign: "center", zIndex: 10 }}>
       <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1px',
                    zIndex: 0,
                }}>
                    <img height={60} src="https://www.doglibre.com/assets/images/svg-icons/logo.gif" />
                    <img height={60} src="https://www.doglibre.com/assets/images/svg-icons/doglibre.png" />
                </div>
                
    </header>
  );
};
